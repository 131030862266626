import { ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";

import HelpDropdown from "molecules/HelpDropdown";
import UserDropdown from "auth/molecules/UserDropdown";
import { useAuth } from "services/AuthContext";

function Nav(): ReactElement {
  const { session } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!session) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className="flex items-center py-3 text-xs md:text-sm leading-5">
      <div className="flex flex-shrink-0 items-center ml-auto space-x-2 md:space-x-4">
        <HelpDropdown />
        <UserDropdown />
      </div>
    </nav>
  );
}

export default Nav;
