import NiceModal from "@ebay/nice-modal-react";
import clsx from "clsx";
import React, { ReactElement, useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Button from "atoms/Button";
import AlertModal from "organisms/AlertModal";
import authActions from "auth/actions";
import { getStatus, hasActive } from "auth/utils";
import { useAuth } from "services/AuthContext";

import { SUBSCRIPTION_STATUS_TRIALING } from "../../../constants";

export interface StripePortalButtonProps {
  type?: "manageSubscription" | "upgrade" | "renew";
}

NiceModal.register("stripe-portal-error", AlertModal);

/**
 * This button allows users with active subscriptions to manage
 * their subscription through the Stripe Customer Portal and
 * redirects inactive users to a plans page where they can purchase
 * a new subscription.
 */
function StripePortalButton({
  type = "manageSubscription",
}: StripePortalButtonProps): ReactElement {
  const { customer, session, supabase } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const handleStripeRequest = useCallback(async () => {
    if (hasActive(customer)) {
      await handlePortalRequest();
    } else {
      history.push("/subscription");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const handlePortalRequest = useCallback(async () => {
    try {
      setIsLoading(true);
      const url: string | undefined = await authActions.getStripeCustomerPortal(
        {
          session,
          supabase,
        }
      );
      setIsLoading(false);
      if (!url) {
        throw new Error(t("error.stripe.no-customer-portal-url"));
      } else {
        window.location.href = url;
      }
    } catch (e) {
      await NiceModal.show("stripe-portal-error", {
        onConfirm: (callback: () => void) => {
          setIsLoading(false);
          callback && callback();
        },
        title: t("global.error.system"),
        children: <p>{t("error.stripe.customer-portal-unavailable")}</p>,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let label;
  switch (getStatus(customer)) {
    case SUBSCRIPTION_STATUS_TRIALING:
      label = "account.action.upgrade";
      break;
    default:
      label = `account.action.${type}`;
      break;
  }

  return (
    <Button
      label={t(label)}
      variant="solid"
      color="primary"
      align="center"
      size="sm"
      onClick={handleStripeRequest}
    >
      <Trans i18nKey={label} t={t} parent="span" />
      <span
        className={clsx("ml-2 w-3 h-3 border-2 loader", !isLoading && "hidden")}
      />
    </Button>
  );
}

export default StripePortalButton;
