import { createClient } from "@supabase/supabase-js";

const { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_ANON_KEY } = process.env;

const supabase = createClient(
  REACT_APP_SUPABASE_URL as string,
  REACT_APP_SUPABASE_ANON_KEY as string
);

export default supabase;
