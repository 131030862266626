import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import Modal from "organisms/Modal";

interface AlertModalProps {
  onConfirm?(callback: () => void): void;
  title: string;
  children?: ReactNode;
}

/**
 * This a NiceModal and must be used within a `NiceModal.Provider`.
 */
export default NiceModal.create(
  ({ onConfirm, title, children }: AlertModalProps) => {
    const modal = useModal();
    const { t } = useTranslation();

    const handleCancel = () => {
      modal.remove();
    };

    const handleConfirm = () => {
      onConfirm ? onConfirm(() => modal.remove()) : modal.remove();
    };

    return (
      <Modal
        title={title}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        showCancel={false}
        confirmText={t("global.action.ok")}
      >
        {children}
      </Modal>
    );
  }
);
