// https://dev.to/icyjoseph/react-slow-suspense-4p02

import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  Suspense,
  useMemo,
  ReactNode,
  ReactElement,
} from "react";

type SlowSuspenseContextType = {
  activate?(): ReturnType<typeof setTimeout>;
  remove?(): void;
};

const SlowSuspenseCtx = createContext({} as SlowSuspenseContextType);

export function SlowSuspense({
  children,
  fallback = null,
}: {
  children: ReactNode;
  fallback: ReactElement | null;
}): ReactElement {
  const [show, setShow] = useState(false);
  const remove = () => setShow(false);
  const activate = () => setTimeout(() => setShow(true), 100);

  const value = useMemo(() => ({ activate, remove }), []);

  return (
    <SlowSuspenseCtx.Provider value={value}>
      {show && fallback}
      <Suspense fallback={<InvokeFallback />}>
        <>{children}</>
      </Suspense>
    </SlowSuspenseCtx.Provider>
  );
}

export function InvokeFallback(): null {
  const { activate, remove } = useContext(SlowSuspenseCtx);

  useEffect(() => {
    const timer = activate && activate();
    return () => {
      timer && clearTimeout(timer);
      remove && remove();
    };
  }, [activate, remove]);

  return null;
}
