interface ServerErrorProps {
  status?: string | number;
  code?: string | number;
}

class ServerError extends Error {
  public code?: string | number;
  public status?: string | number;

  constructor(message: string, props?: ServerErrorProps) {
    super(message);
    Object.setPrototypeOf(this, ServerError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }

    this.status = props?.status;
    this.code = props?.code;
  }

  toString(): string {
    if (this.message !== "" && this.message.match(/\//)) {
      return `error.${this.message.replace("/", ".")}`;
    } else if (this.code && this.status) {
      return `error.code.${this.code}`;
    } else if (this.status) {
      return `error.status.${this.status}`;
    } else {
      return "global.error.generic";
    }
  }
}

export default ServerError;
