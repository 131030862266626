import clsx from "clsx";
import { ReactElement } from "react";

import { StrictUnion } from "../../types";

interface SpinnerProps {
  /**
   * @private
   * Discourage customisation of this property.
   */
  className?: string;
}

interface DefaultProps {
  /**
   * Ignored when `fullScreen` is `true`.
   */
  size: "lg" | "md" | "sm";
}

interface FullScreenProps {
  fullScreen: boolean;
}

const SIZES = {
  fullScreen: "border-8 w-20 h-20",
  lg: "border-4 h-12 w-12",
  md: "border-4 h-6 w-6",
  sm: "border-2 w-4 h-4",
};

function Spinner({
  className,
  size = "md",
  fullScreen = false,
}: SpinnerProps & StrictUnion<DefaultProps | FullScreenProps>): ReactElement {
  // Cannot use translation for loading as it will be used outside of a Suspense component
  const loader = (
    <div
      className={clsx(
        "loader",
        fullScreen ? SIZES["fullScreen"] : SIZES[size as keyof typeof SIZES],
        className
      )}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );

  if (fullScreen) {
    return (
      <div className="flex fixed top-0 left-0 z-50 justify-center items-center w-screen h-screen bg-white">
        {loader}
      </div>
    );
  }

  return loader;
}

export default Spinner;
