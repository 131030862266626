export const BG_COLOR: { [key: string]: string } = {
  primary: "bg-oxford-blue",
  warning: "bg-amber-700",
};

export const TEXT_COLOR: { [key: string]: string } = {
  primary: "text-oxford-blue",
  warning: "text-amber-700",
};

export const PAGE_SIZE = 20;

export const PRODUCT_STANDARD = "Standard";
export const PRODUCT_PLUS = "Plus";
export const PRODUCT_UNKNOWN = "Unknown";

export const SUBSCRIPTION_STATUS_CANCELLED = "canceled";
export const SUBSCRIPTION_STATUS_TRIALING = "trialing";
export const SUBSCRIPTION_STATUS_ACTIVE = ["active", "incomplete", "past_due"];
export const SUBSCRIPTION_STATUS_DUE = ["incomplete", "past_due"];
export const SUBSCRIPTION_STATUS_INACTIVE = [
  "canceled",
  "incomplete_expired",
  "unpaid",
];
