import React, { ReactElement, lazy } from "react";
import { Switch } from "react-router-dom";

import Spinner from "atoms/Spinner";
import DefaultRoute from "routes/DefaultRoute";
import { SlowSuspense } from "behaviors/SlowSuspense";

const PrivateRoute = lazy(() => import("routes/PrivateRoute"));
const Page404 = lazy(() => import("pages/404"));
const Account = lazy(() => import("auth/pages/Account"));
const Integration = lazy(() => import("auth/pages/Integration"));
const Subscription = lazy(() => import("auth/pages/Subscription"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const ResetPassword = lazy(() => import("auth/pages/ResetPassword"));
const ProjectCreate = lazy(() => import("projects/pages/Create"));
const ProjectForm = lazy(() => import("projects/pages/Edit"));
const CollectionCreate = lazy(() => import("collections/pages/Create"));
const CollectionForm = lazy(() => import("collections/pages/Edit"));

function Routes(): ReactElement {
  return (
    <SlowSuspense fallback={<Spinner fullScreen />}>
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} needsActive />
        <PrivateRoute exact path="/account" component={Account} />
        <PrivateRoute exact path="/integration" component={Integration} />
        <PrivateRoute exact path="/subscription" component={Subscription} />
        <DefaultRoute exact path="/reset-password" component={ResetPassword} />
        <PrivateRoute
          exact
          path="/projects/add"
          component={ProjectCreate}
          needsActive
        />
        <PrivateRoute
          exact
          path="/projects/:short_id/edit"
          component={ProjectForm}
          needsActive
        />
        <PrivateRoute
          exact
          path="/projects/:short_id/collections/add"
          component={CollectionCreate}
          needsActive
        />
        <PrivateRoute
          exact
          path="/projects/:project/collections/:short_id/edit"
          component={CollectionForm}
          needsActive
        />
        <DefaultRoute component={Page404} />
      </Switch>
    </SlowSuspense>
  );
}

export default Routes;
