import clsx from "clsx";
import React, { ReactElement } from "react";
import slugify from "slugify";

import { ButtonType } from "../../types";

export type ButtonVariant = "solid" | "inline";
export type ButtonColor = "primary" | "danger" | "light";
export type ButtonSize = "sm" | "md" | "lg";

export interface ButtonProps {
  [key: string]: unknown;
  /**
   * Ignored if `type` is `submit`.
   */
  onClick?(): void;
  label: string;
  type?: ButtonType;
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  block?: boolean;
  disabled?: boolean;
  /**
   * @private
   * Discourage customisation of this property.
   */
  className?: string;
}

const VARIANT: { [key: string]: string } = {
  solid: "btn btn-solid",
  inline: "btn p-1",
};

const SOLID_COLOR: { [key: string]: string } = {
  primary: "btn-oxford-blue",
  danger: "btn-danger",
  light: "btn-light",
};

const INLINE_COLOR: { [key: string]: string } = {
  primary: "text-oxford-blue",
  danger: "text-red-500",
  light: "text-white",
};

const SIZE: { [key: string]: string } = {
  sm: "btn-sm",
  md: "",
  lg: "btn-lg",
};

export default function Button({
  type = "button",
  label,
  variant = "solid",
  color = "primary",
  size = "md",
  block = false,
  disabled = false,
  className,
  onClick,
  ...rest
}: ButtonProps): ReactElement {
  return (
    <button
      type={type}
      id={slugify(label, { lower: true })}
      className={clsx(
        VARIANT[variant],
        variant === "solid" && !!color
          ? SOLID_COLOR[color]
          : INLINE_COLOR[color],
        variant === "inline" && typeof rest?.children === "string"
          ? "px-1"
          : "",
        SIZE[size],
        block ? "w-full" : undefined,
        disabled ? "hover:bg-opacity-70" : undefined,
        className
      )}
      disabled={disabled}
      aria-label={label}
      onClick={type === "button" ? onClick : undefined}
      {...rest}
    />
  );
}
