import clsx from "clsx";
import { ReactElement, ReactNode, useState } from "react";
import slugify from "slugify";

import OutsideClickListener from "behaviors/OutsideClickListener";
import Button from "atoms/Button";
import IconButton from "atoms/IconButton";

import { IconProps, StrictUnion } from "../../types";

import DotsVerticalIcon from "assets/icons/dots-vertical";

export type DropdownMenuVariant = "solid" | "inline";
export type DropdownMenuSize = "sm" | "md" | "lg";

interface DefaultDropdownMenuProps {
  label: string;
  /**
   * Default: `inline`.
   */
  variant?: DropdownMenuVariant;
  color?: "primary";
  /**
   * Default: `md`.
   */
  size?: DropdownMenuSize;
  /**
   * Default: `false`.
   */
  disabled?: boolean;
  children: ReactNode;
}

interface CustomDropdownMenuProps extends DefaultDropdownMenuProps {
  icon({ className, title }: IconProps): ReactElement;
  /**
   * Default: `false`.
   */
  showTitle?: boolean;
}

const SIZE = {
  sm: "w-3 h-3",
  md: "w-4 h-4",
  lg: "w-5 h-5",
};

/**
 * Renders a dropdown menu that displays the menu below and to the left of the toggle.
 */
export default function DropdownMenu({
  label,
  showTitle = false,
  icon,
  variant = "inline",
  color,
  size = "md",
  disabled = false,
  children,
}: StrictUnion<
  DefaultDropdownMenuProps | CustomDropdownMenuProps
>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OutsideClickListener onClick={() => setIsOpen(false)}>
      {showTitle ? (
        <Button
          label={label}
          variant={variant}
          color={color}
          size={size}
          onClick={() => setIsOpen(!isOpen)}
          disabled={disabled}
          aria-haspopup="menu"
        >
          {icon && icon({ className: SIZE[size], title: label })}
          <span className={!!icon ? "text-base" : ""}>{label}</span>
        </Button>
      ) : (
        <IconButton
          label={label as string}
          icon={!!icon ? icon : (args) => <DotsVerticalIcon {...args} />}
          variant={variant}
          color={color}
          size={size}
          onClick={() => setIsOpen(!isOpen)}
          disabled={disabled}
          aria-haspopup="menu"
        />
      )}
      <ul
        className={clsx(
          "absolute top-full right-0 card dropdown",
          isOpen ? "block" : "hidden"
        )}
        aria-labelledby={slugify(label ?? "", { lower: true })}
      >
        {children}
      </ul>
    </OutsideClickListener>
  );
}
