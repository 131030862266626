import axios from "axios";

import { PAGE_SIZE } from "../constants";
import { PaginationRange } from "../types";

const { REACT_APP_SITE_URL } = process.env;
const DEV = REACT_APP_SITE_URL && REACT_APP_SITE_URL.match(/localhost/);

export function getPagination(page = 1, size = PAGE_SIZE): PaginationRange {
  const from = page ? (page - 1) * size : 0;
  const to = page ? from + size - 1 : size - 1;

  return { from, to };
}

export function monitorEvent(body: Record<string, unknown>): void {
  axios
    .post(`${REACT_APP_SITE_URL}/api/monitor/event`, {
      ...body,
    })
    .then((response) => {
      DEV && console.log("SUCCESS: monitor/event", body.eventType, response);
    })
    .catch((error) => {
      DEV && console.error("ERROR: monitor/event", body.eventType, error);
    });
}
