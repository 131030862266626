import { User } from "@supabase/supabase-js";
import {
  MutationFunction,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import authActions from "auth/actions";
import { CustomerType, ProfileType } from "auth/types";
import ServerError from "utils/ServerError";
import { DependencyInjectionProps } from "../types";

export function useChangeEmail(): UseMutationResult {
  return useMutation(authActions.changeEmail as MutationFunction<User>);
}

export function useChangePassword(): UseMutationResult {
  return useMutation(authActions.changePassword as MutationFunction<User>);
}

export function useDeleteAccount(): UseMutationResult {
  return useMutation(authActions.deleteAccount as MutationFunction);
}

export function useProfile(
  di: DependencyInjectionProps
): UseQueryResult<ProfileType> {
  return useQuery(["profile", di?.user?.id], () => authActions.getProfile(di), {
    useErrorBoundary: true,
  });
}

export function useUpdateProfile({
  user,
}: DependencyInjectionProps): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(
    authActions.updateProfile as MutationFunction<ProfileType>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["profile", user?.id]);
      },
    }
  );
}

export function useUpdateApiKey({
  user,
}: DependencyInjectionProps): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(authActions.updateApiKey as MutationFunction<void>, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile", user?.id]);
    },
  });
}

export function useUpdateWebhookUrl({
  user,
}: DependencyInjectionProps): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(authActions.updateWebhookUrl as MutationFunction<void>, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile", user?.id]);
    },
  });
}

export function useStripeCustomer(
  di: DependencyInjectionProps,
  refetchInterval?: number
): UseQueryResult<CustomerType> {
  return useQuery(
    ["stripe", di.user?.id],
    () => authActions.getStripeCustomer(di),
    {
      refetchInterval: refetchInterval ?? undefined,
      useErrorBoundary: (e) => ((e as ServerError).status as number) >= 500,
      enabled: !!di.session,
    }
  );
}

export function useInitialiseStripeCustomer(
  di: DependencyInjectionProps
): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(authActions.initialiseUser as MutationFunction<void>, {
    onSuccess: () => {
      queryClient.invalidateQueries(["stripe", di.user?.id]);
    },
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    onError: (error: any) => {
      if (error?.response?.status === 422) {
        di.supabase.auth.signOut();
      }
    },
  });
}

export function useSubscribeUser(): UseMutationResult {
  return useMutation(authActions.subscribeUser as MutationFunction<void>);
}
