import { ReactElement, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import StripePortalButton from "auth/molecules/StripePortalButton";
import { CustomerType } from "auth/types";
import { getDisplayProduct, getStatus, hasActive } from "auth/utils";
import { useAuth } from "services/AuthContext";

export default function UserSummary(): ReactElement {
  const { customer, user } = useAuth();
  const { t } = useTranslation();

  const [status, setStatus] = useState("");

  useEffect(() => {
    const s = getStatus(customer as CustomerType);

    setStatus(`(${t(`account.status.${s}`) ?? t("account.status.default")})`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  return (
    <div className="py-4 w-60 text-center border-b">
      <span className="block mx-4 font-bold truncate">
        {user?.user_metadata?.email}
      </span>
      {!hasActive(customer as CustomerType) ? (
        <Trans
          i18nKey="account.label.noPlan"
          parent="span"
          className="block mb-4 text-sm"
        />
      ) : (
        <Trans
          i18nKey="account.label.planName"
          parent="span"
          className="block mb-4 text-sm"
          values={{
            plan: getDisplayProduct(customer as CustomerType),
            status,
          }}
        />
      )}
      <StripePortalButton />
    </div>
  );
}
