import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query/types/react/types";

import MenuItem from "atoms/MenuItem";
import DropdownMenu from "molecules/DropdownMenu";
import UserSummary from "auth/molecules/UserSummary";
import { useProfile, useSubscribeUser } from "auth/hooks";
import { ProfileType } from "auth/types";
import { useAuth } from "services/AuthContext";

import ChevronDownIcon from "assets/icons/chevron-down";
import UserSolidIcon from "assets/icons/user-solid";

function UserDropdown(): ReactElement {
  const { supabase, session, user } = useAuth();
  const { t } = useTranslation();

  const profile: UseQueryResult<ProfileType | null> = useProfile({
    supabase,
    session,
    user,
  });
  const subscribeUser = useSubscribeUser();

  const signOut = async () => {
    await supabase.auth.signOut();
  };

  useEffect(() => {
    /**
     * Subscribe user to newsletter.
     */
    if (profile.data && !profile?.data?.meta?.subscribed) {
      subscribeUser.mutate({ di: { session, supabase, user } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.data?.meta?.subscribed]);

  return (
    <DropdownMenu
      label={t("account.label.userMenu")}
      icon={(args) => (
        <>
          <UserSolidIcon {...args} />
          <ChevronDownIcon className="w-3 h-3" />
        </>
      )}
      variant="inline"
      color="primary"
      size="lg"
    >
      <UserSummary />
      <MenuItem title={t("nav.account")} to="/account" />
      <MenuItem title={t("nav.integration")} to="/integration" />
      <MenuItem title={t("nav.logout")} onClick={signOut} />
    </DropdownMenu>
  );
}

export default UserDropdown;
