import clsx from "clsx";
import {
  useCallback,
  useEffect,
  createRef,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import Button from "atoms/Button";
import IconButton from "atoms/IconButton";
import Spinner from "atoms/Spinner";

import XIcon from "assets/icons/x";

export type ModalProps = {
  title: string;
  children: ReactNode;
  formId?: string;
  onConfirm?(): void;
  onCancel?(): void;
  confirmText?: string;
  confirmColor?: "primary" | "danger";
  cancelText?: string;
  showCancel?: boolean;
  prose?: boolean;
};

export default function Modal({
  title = "",
  children,
  formId,
  onConfirm,
  onCancel,
  confirmText,
  confirmColor = "primary",
  cancelText,
  showCancel = true,
  prose = false,
}: ModalProps): ReactElement {
  const [busy, setBusy] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const modalRef = createRef<HTMLDivElement>();
  const { t } = useTranslation();

  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onCancel && onCancel();
      }
    },
    [onCancel]
  );

  const handleCancel = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    if (!formId) {
      setBusy(true);
      onConfirm && onConfirm();
    }
  }, [formId, onConfirm]);

  const handleTab = (e: KeyboardEvent) => {
    const focusableElements = modalRef?.current?.querySelectorAll(
      "a, button, textarea, input, select"
    );
    if (!focusableElements) {
      return;
    }

    const total = focusableElements.length;

    if (!e.shiftKey) {
      activeIndex + 1 === total
        ? setActiveIndex(0)
        : setActiveIndex(activeIndex + 1);

      // @ts-ignore
      focusableElements[activeIndex] && focusableElements[activeIndex].focus();

      return e.preventDefault();
    }

    if (e.shiftKey) {
      activeIndex - 1 < 0
        ? setActiveIndex(total - 1)
        : setActiveIndex(activeIndex - 1);

      // @ts-ignore
      focusableElements[activeIndex] && focusableElements[activeIndex].focus();

      return e.preventDefault();
    }
  };

  const keyListenersMap = new Map([
    [27, handleEscape],
    [9, handleTab],
  ]);

  useEffect(() => {
    function keyListener(e: KeyboardEvent) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", keyListener);

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", keyListener);
    };
  });

  return createPortal(
    <aside
      className="flex fixed inset-0 z-60 items-center"
      style={{ background: "rgba(0,0,0,0.35)" }}
    >
      <div
        ref={modalRef}
        className="inline-flex z-70 mx-auto max-w-full md:max-w-3xl max-h-screen md:max-h-96"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex relative flex-col w-full bg-white rounded border-0 shadow-lg outline-none focus:outline-none">
          <div className="flex justify-between items-center p-4 pb-2 rounded-t">
            <h3 className="m-0 font-sans text-lg font-semibold">{title}</h3>
            <IconButton
              label={t("global.label.closeButton")}
              icon={(args) => <XIcon {...args} />}
              variant="inline"
              color="primary"
              onClick={handleCancel}
            />
          </div>
          <div
            className={clsx(
              "overflow-y-auto relative flex-grow p-4 pt-2 w-full max-w-full",
              prose ? "prose" : undefined
            )}
          >
            {children}
          </div>
          <div className="flex justify-end items-center p-4 pt-2 space-x-2 rounded-b">
            {busy && <Spinner size="sm" className="mr-2" />}
            {showCancel && (
              <Button
                label={cancelText ?? t("global.action.cancel")}
                variant="solid"
                color="light"
                onClick={handleCancel}
              >
                {cancelText ?? t("global.action.cancel")}
              </Button>
            )}
            <Button
              label={confirmText ?? t("global.action.ok")}
              type={formId ? "submit" : "button"}
              variant="solid"
              color={confirmColor}
              onClick={handleConfirm}
              {...{ form: formId ?? undefined }}
            >
              {confirmText ?? t("global.action.ok")}
            </Button>
          </div>
        </div>
      </div>
    </aside>,
    document.getElementById("modal") || document.body
  );
}
