import { ReactElement } from "react";

import Logo from "molecules/Logo";
import Nav from "molecules/Nav";

function Header(): ReactElement {
  return (
    <header role="banner">
      <div className="flex justify-between items-center py-3">
        <Logo />
        <Nav />
      </div>
    </header>
  );
}

export default Header;
