import { ReactElement, ReactNode, useEffect, useRef } from "react";

export type NativeClickListenerProps = {
  onClick(e: MouseEvent): void;
  children?: ReactNode;
};

export default function NativeClickListener({
  onClick,
  children,
}: NativeClickListenerProps): ReactElement {
  const container = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (container.current && !container.current.contains(e.target as Node)) {
      return onClick(e);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={container} className="inline-flex relative">
      {children}
    </div>
  );
}
