import clsx from "clsx";
import { ReactElement } from "react";
import slugify from "slugify";

import { ButtonType, RenderFunction } from "../../types";

export type ButtonVariant = "solid" | "inline";
export type ButtonColor = "primary" | "warning" | "light";
export type ButtonSize = "sm" | "md" | "lg";

export interface IconButtonProps {
  label: string;
  icon: RenderFunction;
  onClick(): void;
  type?: ButtonType;
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  round?: boolean;
  disabled?: boolean;
  /**
   * @private
   * Discourage customisation of this property.
   */
  className?: string;
}

const SOLID_COLOR: { [key: string]: string } = {
  primary: "btn-oxford-blue",
  warning: "btn-warning",
  light: "btn-light",
};

const INLINE_COLOR: { [key: string]: string } = {
  primary: "text-oxford-blue",
  warning: "text-amber-500",
  light: "text-white",
};

const SIZE = {
  sm: "w-3 h-3",
  md: "w-4 h-4",
  lg: "w-5 h-5",
};

function IconButton({
  label,
  icon,
  onClick,
  type = "button",
  variant = "solid",
  color = "primary",
  size = "md",
  round = false,
  disabled = false,
  className,
}: IconButtonProps): ReactElement {
  return (
    <button
      type={type}
      id={slugify(label ?? "", { lower: true })}
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        "p-1 btn",
        variant === "solid" ? SOLID_COLOR[color] : INLINE_COLOR[color],
        round ? "rounded-full" : "",
        className
      )}
      aria-label={label}
    >
      {icon({ className: SIZE[size], title: label })}
      <span className="sr-only">{label}</span>
    </button>
  );
}

export default IconButton;
