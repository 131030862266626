import { User } from "@supabase/supabase-js";
import axios from "axios";
import _identity from "lodash/identity";
import _pick from "lodash/pick";
import _pickBy from "lodash/pickBy";

import { CustomerType, ProfileType } from "auth/types";
import { monitorEvent } from "utils";
import ServerError from "utils/ServerError";
import { DependencyInjectionProps } from "../types";

const { REACT_APP_SITE_URL } = process.env;

export const VALID_KEYS = ["full_name"];
export const VALID_META_KEYS = ["api_key", "webhook_url"];

const getProfile = async ({
  supabase,
  session,
  user,
}: DependencyInjectionProps): Promise<Partial<ProfileType> | undefined> => {
  if (!session || !user) {
    throw new ServerError("Unauthorized", { status: 401 });
  }
  const { data, error, status } = await supabase
    .from("profiles")
    .select("*")
    .eq("id", user.id);
  if (error) {
    throw new ServerError(error.message, { status, code: error.code });
  }
  if (!data || !data[0]) {
    return undefined;
  } else {
    return _pickBy(data[0], _identity);
  }
};

async function updateProfile({
  profile,
  di: { session, supabase, user },
}: {
  profile: ProfileType;
  di: DependencyInjectionProps;
}): Promise<ProfileType | undefined> {
  if (!session || !user) {
    throw new ServerError("Unauthorized", { status: 401 });
  }
  const { data, error, status } = await supabase
    .from("profiles")
    .update(_pick(profile, VALID_KEYS))
    .match({ id: user.id });
  if (error) {
    throw new ServerError(error.message, { status, code: error.code });
  }
  if (!data || !data[0]) {
    return undefined;
  } else {
    return data[0];
  }
}

async function updateApiKey({
  api_key,
  di: { session, supabase, user },
}: {
  api_key: string;
  di: DependencyInjectionProps;
}): Promise<void> {
  if (!session || !user) {
    throw new ServerError("Unauthorized", { status: 401 });
  }
  const body = {
    uid: user.id,
    api_key,
  };
  const { error, status } = await supabase.rpc("update_profile_api_key", body);
  if (error) {
    throw new ServerError(error.message, { status, code: error.code });
  } else {
    return Promise.resolve();
  }
}

async function updateWebhookUrl({
  webhook_url,
  di: { session, supabase, user },
}: {
  webhook_url: string;
  di: DependencyInjectionProps;
}): Promise<void> {
  if (!session || !user) {
    throw new ServerError("Unauthorized", { status: 401 });
  }
  const body = {
    uid: user.id,
    webhook_url,
  };
  const { error, status } = await supabase.rpc(
    "update_profile_webhook_url",
    body
  );
  if (error) {
    throw new ServerError(error.message, { status, code: error.code });
  } else {
    return Promise.resolve();
  }
}

async function initialiseUser({
  di: { session },
}: {
  di: DependencyInjectionProps;
}): Promise<CustomerType | undefined> {
  if (!session) {
    throw new ServerError("Unauthorized", { status: 401 });
  }
  try {
    const { data } = await axios.post(`${REACT_APP_SITE_URL}/api/users`, null, {
      headers: {
        authorization: `Bearer ${session?.access_token}`,
      },
    });
    return data;
  } catch ({ response: { status, statusText } }) {
    throw new ServerError(statusText as string, { status: status as number });
  }
}

async function getStripeCustomer({
  session,
  supabase,
  user,
}: DependencyInjectionProps): Promise<CustomerType | undefined> {
  if (!session || !user) {
    throw new ServerError("Unauthorized", { status: 401 });
  }
  const { data, error, status } = await supabase
    .from("customers")
    .select("*")
    .eq("id", user.id);
  if (error) {
    throw new ServerError(error.message, { status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

async function getStripeCheckoutSession(
  price_plan: string,
  { session }: DependencyInjectionProps
): Promise<string | undefined> {
  if (!session) {
    throw new ServerError("Unauthorized", { status: 401 });
  }
  try {
    const { data } = await axios.post(
      `${REACT_APP_SITE_URL}/api/users/checkout-session`,
      {
        price: price_plan,
      },
      {
        headers: {
          authorization: `Bearer ${session?.access_token}`,
        },
      }
    );
    return data.url;
  } catch ({ response: { status, statusText } }) {
    throw new ServerError(statusText as string, { status: status as number });
  }
}

async function getStripeCustomerPortal({
  session,
}: DependencyInjectionProps): Promise<string | undefined> {
  if (!session) {
    throw new ServerError("Unauthorized", { status: 401 });
  }
  try {
    const { data } = await axios.get(
      `${REACT_APP_SITE_URL}/api/users/customer-portal`,
      {
        headers: {
          authorization: `Bearer ${session?.access_token}`,
        },
      }
    );
    return data.url;
  } catch ({ response: { status, statusText } }) {
    throw new ServerError(statusText as string, { status: status as number });
  }
}

async function changeEmail({
  email,
  di: { supabase },
}: {
  email: string;
  di: DependencyInjectionProps;
}): Promise<User | null> {
  const { data, error } = await supabase.auth.update({ email });
  if (error) {
    throw new ServerError(error.message, {
      status: error.message === "Not logged in." ? 401 : 400,
    });
  } else {
    return data ?? null;
  }
}

async function changePassword({
  password,
  di: { supabase },
}: {
  password: string;
  di: DependencyInjectionProps;
}): Promise<User | null> {
  const { data, error } = await supabase.auth.update({ password });
  if (error) {
    throw new ServerError(error.message, {
      status: error.message === "Not logged in." ? 401 : 400,
    });
  } else {
    return data ?? null;
  }
}

async function deleteAccount({
  di: { session },
}: {
  di: DependencyInjectionProps;
}): Promise<void> {
  if (!session) {
    throw new ServerError("Unauthorized", { status: 401 });
  }
  try {
    const { data } = await axios.delete(`${REACT_APP_SITE_URL}/api/users`, {
      headers: {
        authorization: `Bearer ${session?.access_token}`,
      },
    });
    return data;
  } catch ({ response: { status, statusText } }) {
    throw new ServerError(statusText as string, { status: status as number });
  }
}

async function subscribeUser({
  di: { session, supabase, user },
}: {
  di: DependencyInjectionProps;
}): Promise<void> {
  if (!session || !user) {
    return Promise.reject();
  }
  const body = {
    uid: user.id,
    email: user.email,
  };
  const { error } = await supabase.rpc("add_subscriber", body);
  if (error) {
    monitorEvent({
      eventType: "SubscribeFailed",
      ...body,
      error: error.message,
    });
    return Promise.reject();
  } else {
    monitorEvent({ eventType: "SubscribeEvent", ...body });
    return Promise.resolve();
  }
}

const actions = {
  getProfile,
  updateApiKey,
  updateWebhookUrl,
  updateProfile,
  getStripeCustomer,
  getStripeCheckoutSession,
  getStripeCustomerPortal,
  initialiseUser,
  changeEmail,
  changePassword,
  deleteAccount,
  subscribeUser,
};

export default actions;
