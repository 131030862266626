import { ComponentType, ReactElement, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import toast, { Toaster } from "react-hot-toast";
import { QueryErrorResetBoundary } from "react-query";
import { useLocation } from "react-router-dom";

import Header from "organisms/Header";
import Error500 from "pages/500";

interface DefaultLayoutProps {
  [key: string]: unknown;
  component: ComponentType;
}

export default function DefaultLayout({
  component: Component,
  ...rest
}: DefaultLayoutProps): ReactElement {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const [key, val] = location.hash.substring(1).split("=");
      if (key === "message") {
        window.history.replaceState(
          "",
          document.title,
          location.pathname + location.search
        );
        toast.success(val.replace(/\+/g, " "), {
          position: "bottom-left",
          duration: 4000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main role="main" className="flex flex-col flex-1 px-4 sm:px-6 lg:px-16">
      <Header />
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary FallbackComponent={Error500} onReset={reset}>
            <Component {...rest} />
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
      <Toaster position="bottom-left" />
    </main>
  );
}
