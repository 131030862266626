import { SupabaseClient, Session, User } from "@supabase/supabase-js";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isUndefined from "lodash/isUndefined";
import qs from "query-string";
import {
  ComponentType,
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { useHistory, useLocation } from "react-router-dom";

import { useInitialiseStripeCustomer, useStripeCustomer } from "auth/hooks";
import { CustomerType } from "auth/types";

export interface SupabaseHocType {
  supabase: SupabaseClient;
}

export interface AuthProviderProps {
  supabaseClient: SupabaseClient;
  children: ReactNode;
}

export type AuthContextType = {
  initialising: boolean;
  supabase: SupabaseClient;
  customer: CustomerType;
  user: User;
  session: Session;
};

const AuthContext = createContext({});

function AuthProvider({
  supabaseClient: supabase,
  children,
}: AuthProviderProps): ReactElement {
  const { search } = useLocation();
  const history = useHistory();

  const [customer, setCustomer] = useState<CustomerType>();
  const [session, setSession] = useState<Session | null>(
    supabase.auth.session()
  );
  const [user, setUser] = useState<User | null>(session?.user ?? null);
  const [refetchInterval, setRefetchInterval] = useState<number>();
  const [initialising, setInitialising] = useState(true);

  const { data, isFetched }: UseQueryResult<CustomerType> = useStripeCustomer(
    { session: session as Session, supabase, user: user as User },
    refetchInterval
  );
  const initialiseStripeCustomer = useInitialiseStripeCustomer({
    session: session as Session,
    supabase,
    user: user as User,
  });

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === "SIGNED_IN") {
          setInitialising(true);
        }
        if (event === "PASSWORD_RECOVERY") {
          history.push("/reset-password");
        }

        setSession(session);
        setUser(session?.user ?? null);
      }
    );

    return () => {
      authListener?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!session) {
      setInitialising(false);
      return;
    }

    // User has been deleted
    if (!data && isFetched) {
      setInitialising(false);
      setRefetchInterval(0);
      supabase.auth.signOut();
    }

    // Data received
    if (initialising && !_isEmpty(_get(data, "subscriptions"))) {
      setInitialising(false);
      setRefetchInterval(0);
      setCustomer(data);
    }

    // Email verification
    if (
      initialising &&
      !refetchInterval &&
      !_isUndefined(_get(qs.parse(search), "verified")) &&
      _get(customer, "customer_id", "") !== ""
    ) {
      setRefetchInterval(5000);
      initialiseStripeCustomer.mutate(
        { supabase },
        {
          onSuccess: (c) => {
            setInitialising(false);
            setRefetchInterval(undefined);
            setCustomer(c as CustomerType);
            history.replace({
              search: "",
            });
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, initialising, isFetched, session]);

  const value = {
    supabase,
    initialising,
    customer,
    user,
    session,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within an AuthProvider.`);
  }
  return context as AuthContextType;
}

/**
 * https://stevekinney.github.io/react-and-typescript/higher-order-components
 */
function withSupabase<T>(Component: ComponentType<T & SupabaseHocType>) {
  return (props: Omit<T, keyof SupabaseHocType>): ReactElement => {
    return (
      <AuthContext.Consumer>
        {(context) => (
          <Component
            {...(props as T)}
            supabase={(context as AuthContextType)?.supabase}
          />
        )}
      </AuthContext.Consumer>
    );
  };
}

export { AuthContext, AuthProvider, useAuth, withSupabase };
