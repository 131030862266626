import { ReactElement } from "react";
import { Link } from "react-router-dom";

import LogoIcon from "assets/svg/logo.svg";

const { REACT_APP_SITE_NAME } = process.env;

function Logo(): ReactElement {
  return (
    <div className="flex flex-shrink-0 items-center mr-4">
      <Link
        className="self-center mr-3"
        title={REACT_APP_SITE_NAME}
        style={{ lineHeight: 1 }}
        to="/"
      >
        <img src={LogoIcon} alt={REACT_APP_SITE_NAME} className="w-9 h-9" />
      </Link>
      <div className="overflow-hidden invisible md:visible whitespace-nowrap">
        <Link
          className="font-oxygen text-2xl font-bold text-oxford-blue hover:no-underline"
          title={REACT_APP_SITE_NAME}
          to="/"
        >
          {REACT_APP_SITE_NAME}
        </Link>
      </div>
    </div>
  );
}

export default Logo;
