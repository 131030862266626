/* global newrelic */
import NiceModal from "@ebay/nice-modal-react";
import React, { ReactElement, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { I18nextProvider } from "react-i18next";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import Routes from "routes";

import Spinner from "atoms/Spinner";
import Error500 from "pages/500";
import { AuthProvider } from "services/AuthContext";
import supabase from "services/supabase";
import ScrollToTop from "behaviors/ScrollToTop";
import { SlowSuspense } from "behaviors/SlowSuspense";

import i18n from "./i18n";

function App(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [show500, setShow500] = useState(false);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        suspense: true,
        staleTime: 60 * 1000,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        setShow500(true);
        // @ts-ignore
        newrelic?.noticeError(error, JSON.stringify(error));
      },
    }),
  });

  return (
    <SlowSuspense fallback={<Spinner fullScreen />}>
      <NiceModal.Provider>
        <BrowserRouter basename="/">
          <ErrorBoundary
            FallbackComponent={Error500}
            onReset={() => {
              setShow500(false);
            }}
          >
            <QueryClientProvider client={queryClient}>
              <I18nextProvider i18n={i18n}>
                <AuthProvider supabaseClient={supabase}>
                  <ScrollToTop>
                    <Routes />
                  </ScrollToTop>
                </AuthProvider>
              </I18nextProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </NiceModal.Provider>
    </SlowSuspense>
  );
}

export default App;
