import { Session } from "@supabase/gotrue-js";
import differenceInDays from "date-fns/differenceInDays";
import fromUnixTime from "date-fns/fromUnixTime";
import _get from "lodash/get";
import _isNil from "lodash/isNil";

import { CustomerType, SubscriptionType, PriceType } from "auth/types";

import {
  PRODUCT_PLUS,
  PRODUCT_STANDARD,
  PRODUCT_UNKNOWN,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_TRIALING,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_DUE,
} from "../constants";

const { REACT_APP_STRIPE_STANDARD_PRODUCT, REACT_APP_STRIPE_PLUS_PRODUCT } =
  process.env;

/**
 * Get trialing, active, or future cancelled subscriptions in order of
 * descending `current_period_end`.
 *
 * @param customer
 */
export const getActiveSubscriptions = (
  customer: CustomerType
): SubscriptionType[] => {
  try {
    return (
      customer?.subscriptions
        .filter((s) => {
          const status = _get(s, "status", "unknown");
          return (
            SUBSCRIPTION_STATUS_ACTIVE.includes(status) ||
            status === SUBSCRIPTION_STATUS_TRIALING ||
            (status === SUBSCRIPTION_STATUS_CANCELLED &&
              !_isNil(_get(s, "cancel_at")))
          );
        })
        .sort(
          (a, b) =>
            _get(a, "current_period_end") - _get(b, "current_period_end")
        ) ?? []
    );
  } catch (e) {
    throw e;
  }
};

/**
 * Get the product code.
 *
 * @param customer
 */
export const getProduct = (customer: CustomerType): string =>
  _get(
    getActiveSubscriptions(customer)[0]?.items?.data[0],
    "price.product",
    "unknown"
  ) as string;

/**
 * Get product display name.
 *
 * @param {CustomerType} customer
 */
export const getDisplayProduct = (customer: CustomerType): string => {
  switch (getProduct(customer)) {
    case REACT_APP_STRIPE_PLUS_PRODUCT:
      return PRODUCT_PLUS;
    case REACT_APP_STRIPE_STANDARD_PRODUCT:
      return PRODUCT_STANDARD;
    default:
      return PRODUCT_UNKNOWN;
  }
};

/**
 * Get subscription interval.
 *
 * @param {CustomerType} customer
 */
export const getInterval = (customer: CustomerType): string => {
  return _get(
    getActiveSubscriptions(customer)[0] ?? {},
    "plan.interval",
    "unknown"
  );
};

/**
 * Get subscription status.
 *
 * @param {CustomerType} customer
 */
export const getStatus = (customer: CustomerType): string =>
  _get(getActiveSubscriptions(customer)[0] ?? {}, "status");

/**
 * Does this account have permission to do anything?
 *
 * @param {CustomerType} customer
 */
export const hasActive = (customer: CustomerType): boolean => {
  return getActiveSubscriptions(customer).length > 0;
};

/**
 * Is currently trialing.
 *
 * @param {CustomerType} customer
 */
export const isTrial = (customer: CustomerType): boolean =>
  getStatus(customer) === SUBSCRIPTION_STATUS_TRIALING;

/**
 * Is standard plan.
 *
 * @param {CustomerType} customer
 */
export const isStandard = (customer: CustomerType): boolean =>
  getProduct(customer) === REACT_APP_STRIPE_STANDARD_PRODUCT;

/**
 * Is plus plan.
 *
 * @param {CustomerType} customer
 */
export const isPlus = (customer: CustomerType): boolean =>
  getProduct(customer) === REACT_APP_STRIPE_PLUS_PRODUCT;

/**
 * Is payment due for a customer.
 *
 * @param customer
 */
export const isDue = (customer: CustomerType): boolean =>
  SUBSCRIPTION_STATUS_DUE.includes(getStatus(customer));

/**
 * Has this subscription been cancelled with time remaining.
 *
 * @param {CustomerType} customer
 */
export const isCancelled = (customer: CustomerType): boolean => {
  return getStatus(customer) === SUBSCRIPTION_STATUS_CANCELLED;
};

/**
 * Days remaining of current period.
 *
 * @param {CustomerType} customer
 */
export const planRemaining = (customer: CustomerType): number =>
  differenceInDays(
    fromUnixTime(
      _get(getActiveSubscriptions(customer)[0] ?? {}, "current_period_end")
    ),
    new Date()
  );

/**
 * Days remaining before cancellation.
 *
 * @param {CustomerType} customer
 */
export const cancelledRemaining = (customer: CustomerType): number =>
  differenceInDays(
    fromUnixTime(_get(getActiveSubscriptions(customer)[0] ?? {}, "cancel_at")),
    new Date()
  );

/**
 * Days remaining of trial period.
 *
 * @param {CustomerType} customer
 */
export const trialRemaining = (customer: CustomerType): number =>
  differenceInDays(
    fromUnixTime(
      _get(getActiveSubscriptions(customer)[0] ?? {}, "trial_end") as number
    ),
    new Date()
  );

/**
 * Account is authenticated via email.
 *
 * @param {Session | null} session
 */
export const isEmailProvider = (session: Session | null): boolean =>
  session?.user?.app_metadata?.provider === "email";

/**
 * Get display price.
 *
 * @param {PriceType} price
 */
export const getPrice = (price: PriceType): string => {
  switch (price.currency) {
    case "USD":
    default:
      return `$${price.unit / 100}`;
  }
};
