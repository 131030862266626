import { ReactElement } from "react";

import { IconProps } from "../../types";

function ChevronDownIcon({
  className,
  title = "Chevron down icon",
}: IconProps): ReactElement {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      className={className}
    >
      <title>{title}</title>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );
}

export default ChevronDownIcon;
