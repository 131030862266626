import clsx from "clsx";
import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";

import { StrictUnion } from "../../types";

interface BaseMenuItemProps {
  title: string;
  color?: "danger";
  disabled?: boolean;
  divider?: boolean;
  children?: ReactNode;
}

interface ButtonMenuItemProps {
  /**
   * Will render a `button`.
   */
  onClick(): void;
}

interface RouterMenuItemProps {
  /**
   * Will render a `react-router` `Link`.
   */
  to: string;
}

interface AnchorMenuItemProps {
  /**
   * Will render an `a` anchor.
   */
  href: string;
  /**
   * Will set `target=_blank` on anchor if `href` is set.
   */
  external?: boolean;
}

export type MenuItemProps = BaseMenuItemProps &
  StrictUnion<ButtonMenuItemProps | RouterMenuItemProps | AnchorMenuItemProps>;

/**
 * `MenuItem` should be used within a list and must contain one of `onClick`, `to`, or `href`.
 */
function MenuItem({
  title,
  onClick,
  color,
  to,
  href,
  external = false,
  disabled,
  divider = false,
  children,
}: MenuItemProps): ReactElement {
  return (
    <li className={clsx("dropdown-item", divider ? "border-t" : "")}>
      {onClick ? (
        <button
          type="button"
          onClick={onClick as () => void}
          title={title}
          className={clsx(color ? color : undefined)}
          disabled={disabled}
        >
          {children ?? title}
        </button>
      ) : to ? (
        <Link
          to={to as string}
          className={clsx(disabled ? "disabled" : "")}
          onClick={disabled ? (e) => e.preventDefault() : undefined}
        >
          {title}
        </Link>
      ) : (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          title={title}
          href={href as string}
          className={clsx("space-x-1", disabled ? "disabled" : "")}
          target={external ? "_blank" : undefined}
          onClick={disabled ? (e) => e.preventDefault() : undefined}
          rel={external ? "noreferrer" : undefined}
        >
          {children ?? title}
        </a>
      )}
    </li>
  );
}

export default MenuItem;
