import mailgo from "mailgo";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";

import MenuItem from "atoms/MenuItem";
import DropdownMenu from "molecules/DropdownMenu";

import ExternalLinkIcon from "assets/icons/external-link";
import QuestionSolidIcon from "assets/icons/question-solid";

const { REACT_APP_MARKETING_URL, REACT_APP_SUPPORT_EMAIL } = process.env;

/**
 * Renders a dropdown menu that displays the menu below and to the left of the toggle.
 */
function HelpDropdown(): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    mailgo();
  }, []);

  return (
    <DropdownMenu
      label={t("nav.support")}
      icon={(args) => <QuestionSolidIcon {...args} />}
      showTitle
      variant="inline"
      color="primary"
      size="lg"
    >
      <li className="dropdown-heading">{t("nav.resources")}</li>
      <MenuItem
        title={t("nav.knowledgeBase")}
        href={`${REACT_APP_MARKETING_URL}/help`}
        external
      >
        <span>{t("nav.knowledgeBase")}</span>
        <ExternalLinkIcon
          className="inline -mt-0.5 w-4 h-4"
          title={t("nav.knowledgeBase")}
        />
      </MenuItem>
      <MenuItem
        title={t("nav.blog")}
        href={`${REACT_APP_MARKETING_URL}/blog`}
        external
      >
        <span>{t("nav.blog")}</span>
        <ExternalLinkIcon
          className="inline -mt-0.5 w-4 h-4"
          title={t("nav.blog")}
        />
      </MenuItem>
      <MenuItem
        title={t("nav.contact")}
        href={`mailto:${REACT_APP_SUPPORT_EMAIL}`}
        external
        divider
      />
    </DropdownMenu>
  );
}

export default HelpDropdown;
