import mailgo from "mailgo";
import React, { ReactElement, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ServerDown from "assets/svg/server_down.svg";
import ServerError from "utils/ServerError";

interface Error500Props {
  error?: Error;
}

const { REACT_APP_SUPPORT_EMAIL } = process.env;

function Error500({ error }: Error500Props): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    mailgo();
  }, []);

  return (
    <div className="flex flex-col justify-center items-center px-4 my-12 w-full text-center">
      <img
        src={ServerDown}
        alt={t("image.serverDown")}
        className="w-full md:w-1/3"
      />
      <h1 className="mt-8">
        {t(`error.code.${(error as ServerError).code}`) ??
          t("global.error.500")}
      </h1>
      <Trans
        i18nKey="global.message.internalServerError"
        t={t}
        parent="p"
        className="mb-8 text-gray-500"
        components={[
          /* eslint-disable-next-line jsx-a11y/anchor-has-content */
          <a href={`mailto:${REACT_APP_SUPPORT_EMAIL}`} />,
        ]}
      />
      <Link to="/" className="btn btn-sm btn-solid btn-oxford-blue">
        {t("global.action.returnHome")}
      </Link>
    </div>
  );
}

export default Error500;
