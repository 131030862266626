import { ComponentType, ReactElement } from "react";
import { Route, RouteProps } from "react-router-dom";

import DefaultLayout from "layouts/DefaultLayout";

interface DefaultRouteProps extends RouteProps {
  [key: string]: unknown;
  component: ComponentType;
}

export default function DefaultRoute({
  component,
  showFab,
  ...rest
}: DefaultRouteProps): ReactElement {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout component={component} showFab={showFab} {...props} />
      )}
    />
  );
}
